const PRODUCT = "PARAMEDIC";
const TITLE = "Thieme retten Prüfungstrainer Rettungssanitäter";
const COOKIE_ID = "ae9eaedf-ce75-44b5-85e6-2015741dfa64";
const COOKIE_TEST_ID = "ae9eaedf-ce75-44b5-85e6-2015741dfa64-test";
const SERVICE_P = "https://examenonline-service.thieme.de";
const SERVICE_Q = "https://examenonline-service-q.thieme.de";
const SERVICE_E = "https://examenonline-service-e.thieme.de";
const SERVICE_LOCAL = "https://examenonline-service-e.thieme.de";
// const SERVICE_LOCAL = "/api";
const CDN_P = "https://cdn.thieme.de";
const CDN_Q = "https://cdn.thieme.de";
const CDN_LOCAL = "http://localhost:5000";
const OPENTEXT_P = "https://cdn.thieme.de/OpenText11/exon-snippets/snippets/final/de/exon/";
const OPENTEXT_Q = "https://cdn-q.thieme.de/OpenText11/exon-snippets/snippets/final/de/exon/";
const ACCOUNT_LOST_P = "https://kundenservice.thieme.de/de/account-email-vergessen.htm";
const ACCOUNT_LOST_Q = "https://kundenservice-q.thieme.de/de/account-email-vergessen.htm";
const AUTHORS_FILE = "authors_paramedic.json";
const AGB_FILE = "agb_paramedics.json";
const TOPFEATURES_FILE = "topfeatures_paramedics.json";
const FAQS_FILE = "faq_paramedics.json";
const snippetsMediaPath = "/OpenText11/exon-snippets/snippets/final/de/exon-media/";
const snippetsPath = "/OpenText11/exon-snippets/snippets/final/de/exon/";
const snippetsImagePath = "/OpenText11/exon-snippets/snippets/final/de/exon-img/";
const imagePath = "/ExamenOnline/startpage/paramedic/";
const GTM_STRING = "&gtm_auth=Zcur3HXKjcJnw473Pm6yGA&gtm_preview=env-3&gtm_cookies_win=x";
const GTM_ID = "GTM-TRW5B5B";
const TRIAL_REDEEM_CODE = "TrialRS";
const TRIAL_DURATION = "2 Tage";
const POOL = "PARAMEDIC";

const STATUS_DASHBOARD_LOCAL = {
  STATUS_DASHBOARD_IS_ACTIVE: true,
  PUBLIC_URL: "https://status-qthiemede-1658482595845.site24x7statusiq.eu/",
  CHECK_INTERVAL_IN_MINUTES: 5,
  WEBHOOK_BASE_URL: "https://webhooks-q.thieme.de/statusiq/v1/component_status",
  DASHBOARD_ID: "24155000038665001",
  COMPONENT_ID: "24155000039513001",
};
const STATUS_DASHBOARD_E = {
  STATUS_DASHBOARD_IS_ACTIVE: true,
  PUBLIC_URL: "https://status-qthiemede-1658482595845.site24x7statusiq.eu/",
  CHECK_INTERVAL_IN_MINUTES: 5,
  WEBHOOK_BASE_URL: "https://webhooks-q.thieme.de/statusiq/v1/component_status",
  DASHBOARD_ID: "24155000038665001",
  COMPONENT_ID: "24155000039513001",
};
const STATUS_DASHBOARD_Q = {
  STATUS_DASHBOARD_IS_ACTIVE: true,
  PUBLIC_URL: "https://status-qthiemede-1658482595845.site24x7statusiq.eu/",
  CHECK_INTERVAL_IN_MINUTES: 5,
  WEBHOOK_BASE_URL: "https://webhooks-q.thieme.de/statusiq/v1/component_status",
  DASHBOARD_ID: "24155000038665001",
  COMPONENT_ID: "24155000039513001",
};
const STATUS_DASHBOARD_P = {
  STATUS_DASHBOARD_IS_ACTIVE: false,
  PUBLIC_URL: "https://status.thieme.de",
  CHECK_INTERVAL_IN_MINUTES: 5,
  WEBHOOK_BASE_URL: "https://webhooks-q.thieme.de/statusiq/v1/component_status",
  DASHBOARD_ID: "24155000002389003",
  COMPONENT_ID: "24155000039513007",
};

const P = {
  CDN: {
    HOST: CDN_P,
    IMAGE_PATH: CDN_P + imagePath,
    SNIPPETS_PATH: CDN_Q + snippetsPath,
    OPENTEXT_IMAGE_PATH: CDN_P + snippetsImagePath,
  },
  OPENTEXT: {
    AGB_FILE: OPENTEXT_P + AGB_FILE,
    FAQS_FILE: OPENTEXT_P + FAQS_FILE,
    AUTHORS_FILE: OPENTEXT_P + AUTHORS_FILE,
    TOPFEATURES_FILE: OPENTEXT_P + TOPFEATURES_FILE,
    MEDIA_PATH: CDN_P + snippetsMediaPath,
  },
  TITLE: TITLE,
  SERVICE: SERVICE_P,
  GTM_STRING: "",
  GTM_ID: GTM_ID,
  COOKIE_ID: COOKIE_ID,
  TRIAL_REDEEM_CODE: TRIAL_REDEEM_CODE,
  TRIAL_DURATION: TRIAL_DURATION,
  POOL: POOL,
  STATUS_DASHBOARD_IS_ACTIVE: STATUS_DASHBOARD_P.STATUS_DASHBOARD_IS_ACTIVE,
  STATUS_DASHBOARD_PUBLIC_URL: STATUS_DASHBOARD_P.PUBLIC_URL,
  STATUS_DASHBOARD_URL: [
    STATUS_DASHBOARD_P.WEBHOOK_BASE_URL,
    STATUS_DASHBOARD_P.DASHBOARD_ID,
    STATUS_DASHBOARD_P.COMPONENT_ID,
  ].join("/"),
  ACCOUNT_LOST: ACCOUNT_LOST_P,
};

const Q = {
  CDN: {
    HOST: CDN_Q,
    IMAGE_PATH: CDN_Q + imagePath,
    SNIPPETS_PATH: CDN_Q + snippetsPath,
    OPENTEXT_IMAGE_PATH: CDN_Q + snippetsImagePath,
  },
  OPENTEXT: {
    AGB_FILE: OPENTEXT_Q + AGB_FILE,
    FAQS_FILE: OPENTEXT_Q + FAQS_FILE,
    AUTHORS_FILE: OPENTEXT_Q + AUTHORS_FILE,
    MEDIA_PATH: CDN_Q + snippetsMediaPath,
    TOPFEATURES_FILE: OPENTEXT_Q + TOPFEATURES_FILE,
  },
  TITLE: TITLE,
  SERVICE: SERVICE_Q,
  GTM_STRING: GTM_STRING,
  GTM_ID: GTM_ID,
  COOKIE_ID: COOKIE_TEST_ID,
  TRIAL_REDEEM_CODE: TRIAL_REDEEM_CODE,
  TRIAL_DURATION: TRIAL_DURATION,
  POOL: POOL,
  STATUS_DASHBOARD_IS_ACTIVE: STATUS_DASHBOARD_Q.STATUS_DASHBOARD_IS_ACTIVE,
  STATUS_DASHBOARD_PUBLIC_URL: STATUS_DASHBOARD_Q.PUBLIC_URL,
  STATUS_DASHBOARD_URL: [
    STATUS_DASHBOARD_Q.WEBHOOK_BASE_URL,
    STATUS_DASHBOARD_Q.DASHBOARD_ID,
    STATUS_DASHBOARD_Q.COMPONENT_ID,
  ].join("/"),
  ACCOUNT_LOST: ACCOUNT_LOST_Q,
};

const E = {
  CDN: {
    HOST: CDN_Q,
    IMAGE_PATH: CDN_Q + imagePath,
    SNIPPETS_PATH: CDN_Q + snippetsPath,
    OPENTEXT_IMAGE_PATH: CDN_Q + snippetsImagePath,
  },
  OPENTEXT: {
    AGB_FILE: OPENTEXT_Q + AGB_FILE,
    FAQS_FILE: OPENTEXT_Q + FAQS_FILE,
    AUTHORS_FILE: OPENTEXT_Q + AUTHORS_FILE,
    MEDIA_PATH: CDN_Q + snippetsMediaPath,
    TOPFEATURES_FILE: OPENTEXT_Q + TOPFEATURES_FILE,
  },
  TITLE: TITLE,
  SERVICE: SERVICE_E,
  GTM_STRING: GTM_STRING,
  GTM_ID: GTM_ID,
  COOKIE_ID: COOKIE_TEST_ID,
  TRIAL_REDEEM_CODE: TRIAL_REDEEM_CODE,
  TRIAL_DURATION: TRIAL_DURATION,
  POOL: POOL,
  STATUS_DASHBOARD_IS_ACTIVE: STATUS_DASHBOARD_E.STATUS_DASHBOARD_IS_ACTIVE,
  STATUS_DASHBOARD_PUBLIC_URL: STATUS_DASHBOARD_E.PUBLIC_URL,
  STATUS_DASHBOARD_URL: [
    STATUS_DASHBOARD_E.WEBHOOK_BASE_URL,
    STATUS_DASHBOARD_E.DASHBOARD_ID,
    STATUS_DASHBOARD_E.COMPONENT_ID,
  ].join("/"),
  ACCOUNT_LOST: ACCOUNT_LOST_Q,
};

const LOCAL = {
  CDN: {
    HOST: CDN_LOCAL,
    IMAGE_PATH: CDN_P + imagePath,
    SNIPPETS_PATH: CDN_P + snippetsPath,
    OPENTEXT_IMAGE_PATH: CDN_P + snippetsImagePath,
  },
  OPENTEXT: {
    AGB_FILE: OPENTEXT_Q + AGB_FILE,
    FAQS_FILE: OPENTEXT_Q + FAQS_FILE,
    AUTHORS_FILE: OPENTEXT_Q + AUTHORS_FILE,
    MEDIA_PATH: CDN_Q + snippetsMediaPath,
    TOPFEATURES_FILE: OPENTEXT_Q + TOPFEATURES_FILE,
  },
  TITLE: TITLE,
  SERVICE: SERVICE_LOCAL,
  GTM_STRING: GTM_STRING,
  GTM_ID: GTM_ID,
  COOKIE_ID: COOKIE_TEST_ID,
  TRIAL_REDEEM_CODE: TRIAL_REDEEM_CODE,
  TRIAL_DURATION: TRIAL_DURATION,
  POOL: POOL,
  STATUS_DASHBOARD_IS_ACTIVE: STATUS_DASHBOARD_LOCAL.STATUS_DASHBOARD_IS_ACTIVE,
  STATUS_DASHBOARD_PUBLIC_URL: STATUS_DASHBOARD_LOCAL.PUBLIC_URL,
  STATUS_DASHBOARD_URL: [
    STATUS_DASHBOARD_LOCAL.WEBHOOK_BASE_URL,
    STATUS_DASHBOARD_LOCAL.DASHBOARD_ID,
    STATUS_DASHBOARD_LOCAL.COMPONENT_ID,
  ].join("/"),
  ACCOUNT_LOST: ACCOUNT_LOST_Q,
};

const config =
  process.env.GATSBY_THIEMESYSTEM === "P"
    ? P
    : process.env.GATSBY_THIEMESYSTEM === "Q"
    ? Q
    : process.env.GATSBY_THIEMESYSTEM === "E"
    ? E
    : process.env.GATSBY_THIEMESYSTEM === "LOCAL"
    ? LOCAL
    : {};

export default {
  ...config,
  PRODUCT,
  PRODUCT_TITLE_SHORT: "Prüfungstrainer Rettungssanitäter",
};
