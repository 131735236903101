import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons/faQuestion";
import { faComment, faEye, faPlayCircle } from "@fortawesome/free-regular-svg-icons";
import {
  CiLine1,
  CiLine2,
  CiLine2Left,
  CiLine2Right,
} from "@thieme/startpage-components/src/components";
import { LogoRetten } from "@thieme/startpage-components/src/assets";
import {
  Menu,
  HeaderStyled,
  MenuWrapper,
  MenuRight,
  LoginButton,
  RegisterButton,
  LogoLink,
  StatusIcon,
} from "@thieme/startpage-components/src/components";
import config from "../config";

const Header = ({ statusCode }) => (
  <HeaderStyled>
    <CiLine1 />
    <CiLine2>
      <CiLine2Left>
        <LogoLink to="/">
          <LogoRetten />
        </LogoLink>
      </CiLine2Left>
      <CiLine2Right></CiLine2Right>
    </CiLine2>

    <MenuWrapper>
      <MenuRight>
        {statusCode > 1 && (
          <StatusIcon
            product={config.PRODUCT_TITLE_SHORT}
            statusCode={statusCode}
            publicDashboardUrl={config.STATUS_DASHBOARD_PUBLIC_URL}
            design={"border"}
          />
        )}
        <LoginButton href="/kreuzen">Login</LoginButton>
        <RegisterButton to="/registrieren">registrieren</RegisterButton>
      </MenuRight>
      <Menu>
        <Menu.MainLink to="/">Home</Menu.MainLink>
        <Menu.MainLink to="/produktinfo/zugang-preise">Zugang/Preise</Menu.MainLink>
        <Menu.Block title="Inhalt">
          <Menu.SubLink to="/produktinfo/pruefungsfragen">
            <FontAwesomeIcon icon={faQuestion} />
            Prüfungsfragen
          </Menu.SubLink>
          <Menu.SubLink to="/produktinfo/kommentare">
            <FontAwesomeIcon icon={faComment} />
            Kommentare
          </Menu.SubLink>
          <Menu.SubLink to="/produktinfo/steckbriefe">
            <FontAwesomeIcon icon={faEye} />
            Steckbriefe
          </Menu.SubLink>
          <Menu.SubLink to="/produktinfo/audios_videos">
            <FontAwesomeIcon icon={faPlayCircle} />
            Audios &amp; Videos
          </Menu.SubLink>
        </Menu.Block>
        <Menu.MainLink to="/produktinfo/features">Features</Menu.MainLink>
        <Menu.MainLink to="/produktinfo/autoren">Autoren</Menu.MainLink>
        <Menu.MainLink to="/produktinfo/faq">FAQ</Menu.MainLink>
      </Menu>
    </MenuWrapper>
  </HeaderStyled>
);

export default Header;
